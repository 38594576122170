import { useStoreActions, useStoreState } from 'easy-peasy';
import React, { useCallback, useEffect, useState } from 'react';

const reqIcon = require.context("./icons", true, /\.png$/);
const icons = reqIcon.keys().map((key) => {
    return reqIcon(key);
});

const reqSound = require.context("./sounds", true, /\.wav$/);
const sounds = reqSound.keys().map((key) => {
    return reqSound(key);
});

const PrutButton = (props) => {
    const [rotation, setRotation] = useState(0);
    const [animationDuration, setAnimationDuration] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [soundFiles, setSoundFiles] = useState([]);
    const [currentIcon, setCurrentIcon] = useState('');
    const incrementScore = useStoreActions((actions) => actions.incrementScore);
    const score = useStoreState((state) => state.score);

    useEffect(() => {
        let index = Math.ceil(score / 10000);
        if (index > icons.lenght) {
            index = index - icons.lenght;
        }
        const icon = icons[index];

        if (icon != currentIcon) {
            setCurrentIcon(icon);
        }

    }, [score, currentIcon, setCurrentIcon]);

    useEffect(() => {
        setIsLoading(true);

        const loadingPromises = sounds.map(url => {
            return new Promise((resolve, reject) => {
                try {
                    const audio = new Audio();
                    audio.src = url;
                    audio.crossOrigin = "anonymous";

                    const onAudioLoaded = (e) => {
                        audio.removeEventListener("canplaythrough", onAudioLoaded);
                        console.log('loaded', url);
                        resolve();
                        soundFiles.push(audio);
                    }

                    audio.addEventListener("canplaythrough", onAudioLoaded);

                    console.log('loading', url);
                    audio.load();
                } catch {
                    console.log("loading failed", url);
                    reject();
                }
            });
        });

        Promise.all(loadingPromises).finally(() => { setIsLoading(false); });

    }, [setIsLoading]);

    const onClick = useCallback(() => {
        if (isLoading) {
            return;
        }

        const fileIndex = Math.floor(Math.random() * soundFiles.length);
        //soundFiles[fileIndex].play();
        const soundFile = soundFiles[fileIndex];
        soundFile.play();

        const randomRotation = Math.floor(Math.random() * 3000);
        const diff = Math.abs(randomRotation - rotation);
        incrementScore({ duration: soundFile.duration * 1000, stepSize: Math.floor(diff / 1000) + 1 });
        setRotation(randomRotation);
        setAnimationDuration(soundFile.duration);

    }, [isLoading, rotation, setRotation, soundFiles, incrementScore, animationDuration, setAnimationDuration]);

    const style = {
        alignSelf: 'center',
        fontSize: 100,
        // backgroundColor: 'inherit',
        // border: 'inherit',
        transform: 'rotate(' + rotation + 'deg)',
        transitionDuration: animationDuration + 's',
        width: '80%'
    }

    return (
        <React.Fragment>
            <div
                style={style}
                onClick={onClick}
            >{isLoading
                ? 'loading' :
                <img style={{ width: '100%' }} src={currentIcon} />}</div>
        </React.Fragment>
    );
}

export default PrutButton;

