import React, { useEffect } from "react";
import { useStoreState } from "easy-peasy";

const style = {
    fontSize: 50,
    alignSelf: 'center',
}

const Score = (props) => {
    const score = useStoreState((state) => state.score);

    return (
        <div style={style}>
            <p style={{ marginTop: 0 }}>{score}</p>
        </div>
    )
}

export default Score;