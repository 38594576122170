import React, { useEffect } from "react";
import version from '../../version.dummy'

const style = {
    fontSize: 10,
    alignSelf: 'center',
    marginTop: 'auto',
}

const Version = (props) => {
    return (
        <div style={style}>
            <p style={{ margin: 0 }}>{version.replace(".dummy", "")}</p>
        </div>
    )
}

export default Version;