import { createStore, thunk, action, persist } from "easy-peasy";

const store = createStore(persist({
    score: 0,
    stepScore: action((state, payload) => {
        const step = payload;
        state.score += step;
    }),
    incrementScore: thunk((actions, payload) => {

        const until = new Date().getTime() + payload.duration;
        const stepSize = payload.stepSize;

        const stepper = () => {
            actions.stepScore(stepSize);
            if (new Date().getTime() < until) {
                window.requestAnimationFrame(stepper);
            }
        }

        window.requestAnimationFrame(stepper);
    }),
}, { storage: 'localStorage' }));

export default store;