import React from "react";


const style = {
    display: "flex",
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between'
}

const App = (props) => {
    return <div style={style}>{props.children}</div>
}

export default App;