import React from 'react';
import ReactDOM from 'react-dom';
import store from './store';
import { StoreProvider } from 'easy-peasy';

import App from './app';
import PrutButton from './components/prut-button';
import Score from './components/score';
import Version from './components/version';

const root = document.getElementById('app');

if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/service-worker.js').then(registration => {
            console.log('SW registered: ', registration);
        }).catch(registrationError => {
            console.log('SW registration failed: ', registrationError);
        });
    });
}

ReactDOM.render(
    <App>
        <StoreProvider store={store}>
            <Score />
            <PrutButton />
            <Version />
        </StoreProvider>
    </App>,
    root
);